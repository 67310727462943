<template>
  <div class="card card-custom card-sticky">
    <notifications group="notification" position="top right" />
    <div class="card-header">
      <div class="card-title">
        <h3 class="card-label">
          <span class="svg-icon svg-icon-primary svg-icon-2x">
            <inline-svg src="media/svg/icons/Map/Position.svg" />
          </span>
        </h3>
      </div>
      <div class="card-toolbar">
        <router-link
          to="/cities/list"
          class="btn btn-light-primary font-weight-bolder mr-2"
        >
          <i class="ki ki-long-arrow-back icon-sm"></i>
          {{ $t('CITIES.ALL_CITIES') }}
        </router-link>
        <div class="js-submit-options btn-group">
          <button
            @click="patchEntity"
            type="button"
            class="btn btn-primary font-weight-bolder"
          >
            <i class="ki ki-check icon-sm"></i>
            {{ $t('CITIES.UPDATE_CITY') }}
          </button>
        </div>
      </div>
    </div>
    <div v-if="isCitiesLoaded" class="card-body">
      <div class="row">
        <div class="col-xl-2"></div>
        <div class="col-xl-8">
          <div class="my-5">
            <h3 class="text-dark font-weight-bold mb-10">Info:</h3>
            <div class="form-group row">
              <label class="col-3">{{ $t('GENERAL.NAME') }}:</label>
              <div class="col-9">
                <input
                  v-model="city.name"
                  class="form-control form-control-solid"
                  type="text"
                  placeholder="Zagreb"
                  @input="checkCityExistence"
                />
                <span v-if="isExist" class="text-danger">
                  City already exists in the database!
                </span>
              </div>
            </div>
          </div>
        </div>
        <div class="col-xl-2"></div>
      </div>
    </div>
    <div v-else class="col-12 text-center py-5">
      <b-spinner variant="primary" label="Spinning"></b-spinner>
    </div>
  </div>
</template>

<script>
import ApiService from '@/core/services/api.service'
import { mapGetters } from 'vuex'
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module'

export default {
  name: 'EditCity',
  data() {
    return {
      city: {
        name: null,
      },
      cities: [], // To store all cities
      isExist: false, // To check if city name exists
      isCitiesLoaded: false,
      realCityName: null,
    }
  },
  computed: {
    ...mapGetters([
      'currentUserRole',
    ]),
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: 'Edit City', route: '' }])
    ApiService.get('cities', this.$route.params.id).then(({ data }) => {
      this.city.name = data.name;
      this.realCityName = data.name;
    })
    this.loadCities();
  },
  methods: {
    loadCities() {
      this.isCitiesLoaded = false;

      ApiService.get('cities', '?pagination=false') 
        .then(({ data }) => {
          this.cities = [...data['hydra:member']];
          this.isCitiesLoaded = true;
        })
        .catch(() => {
          this.$notify({
            group: 'notification',
            type: 'error',
            title: 'Error fetching cities list',
          });
        });
    },


    checkCityExistence() {
      this.isExist = this.cities.filter((city) => city.name.toLowerCase() !== this.realCityName.toLowerCase()).some(city => city.name.toLowerCase() === this.city.name.trim().toLowerCase());
    },
    patchEntity() {
      this.isExist = false

      if (this.currentUserRole === 'ROLE_ROOT' || this.currentUserRole === 'ROLE_SUPER_ADMIN')
      {
        if (!this.city.name || this.city.name.trim() === '') {
          this.$notify({
            group: 'notification',
            type: 'error',
            title: 'City name cannot be empty',
          });
          return;
        }

        this.cities.forEach((city) => {
          if (city.name === this.city.name && city.id !== this.$route.params.id) {
            this.isExist = true
          }
        })

        if (this.isExist) {
          this.$notify({
            group: 'notification',
            type: 'error',
            title: 'City already in the database',
          });
          return;
        } else {
          ApiService.update('cities', this.$route.params.id, this.city)
            .then(() => {
              this.$notify({
                group: 'notification',
                type: 'success',
                title: 'City successfully updated',
              })

              setTimeout(() => {
                this.$router.push({ name: 'cities-list' })
              }, 500)
            })
            .catch(() => {
               this.$notify({
                group: 'notification',
                type: 'error',
                title: 'Error adding city',
              })
            })
        }
    }

  }}
}
</script>
